import { useMemo } from 'react'

import {
  BLOCK_DATES,
  PAYMENT_TYPE_PAY_ID,
  TRAJECTORY_RUSSIA_ID,
} from './../common/constants'
import type { ViewerElementStatements } from './../models'

type Props = {
  scenarioKeys?: any
  statement?: ViewerElementStatements | null
}

const useBlockSendRequest = (props: Props) => {
  const { scenarioKeys, statement } = props

  const isBlockSendRequestBakRusPay = useMemo(() => {
    const currentTime = new Date()
    const blockSendAppDate = new Date(BLOCK_DATES.BAK_RUSSIA_PAY)
    const paymentType = statement?.paymentType === PAYMENT_TYPE_PAY_ID
    const isRus = statement?.trajectoryId === TRAJECTORY_RUSSIA_ID

    const isBakRus =
      scenarioKeys.level_training_bachelor.tdId === statement?.scenario.id

    return isRus && paymentType && isBakRus && currentTime >= blockSendAppDate
  }, [statement, scenarioKeys, BLOCK_DATES, PAYMENT_TYPE_PAY_ID])

  const isBlockSendRequestMagRusPay = useMemo(() => {
    const currentTime = new Date()
    const blockSendAppDate = new Date(BLOCK_DATES.MAG_RUSSIA_PAY)
    const paymentType = statement?.paymentType === PAYMENT_TYPE_PAY_ID
    const isRus = statement?.trajectoryId === TRAJECTORY_RUSSIA_ID

    const isBakRus = scenarioKeys.magistra_rf.tdId === statement?.scenario.id

    return isRus && paymentType && isBakRus && currentTime >= blockSendAppDate
  }, [statement, scenarioKeys, BLOCK_DATES, PAYMENT_TYPE_PAY_ID])

  return { isBlockSendRequestBakRusPay, isBlockSendRequestMagRusPay }
}

export default useBlockSendRequest
