import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

import { CONTRACT_REVOKE, CONTRACT_DENIED } from '@common/constants'
import terms from '@common/terms'
import ContractList from '@components/ContractList'
import useAppSelector from '@hooks/useAppSelector'
import {
  selectContractsList,
  selectStatementsElement,
} from '@redux/actions/selectors'
import RouteButtonLink from '@ui/RouteButtonLink'

const ContractListPage = (scenarioKeys) => {
  const statement = useAppSelector(selectStatementsElement)

  const isRF = Number(statement?.trajectory?.id) === 1
  const contracts = useAppSelector(selectContractsList)
  const [validStatuses, setValidStatuses] = useState(true)
  const scenarioId = statement?.scenarioId

  useEffect(() => {
    if (!!contracts && contracts.data?.length > 0) {
      contracts.data.forEach((contract) => {
        if (
          Number(contract.status.code) !== CONTRACT_REVOKE &&
          Number(contract.status.code) !== CONTRACT_DENIED
        ) {
          setValidStatuses(false)
        }
      })
    }
  })

  if (
    scenarioId === scenarioKeys.asperatnuta_rf.tdId ||
    scenarioId === scenarioKeys.level_training_preparation.tdId
  ) {
    return (
      <div>
        <h2 style={{ fontWeight: 'bold', textAlign: 'center' }} className={'header'}>
          Уважаемый абитуриент!
        </h2>

        <p className={'mb-12'}>
          У Вас есть возможность заключить договор об оказании образовательных услуг
          двумя способами: лично и дистанционно.
        </p>
        <p className={'mb-12'} style={{ fontWeight: 'bold' }}>
          Заключение договора об оказании образовательных услуг лично осуществляется
          в отделе аспирантуры по адресу: наб. р. Мойки, 48, корпус 5, этаж 3,
          комната 302.
        </p>
        <p className={'mb-12'}>
          После совершения оплаты необходимо предоставить лично или направить
          подтверждающий документ (чек) на e-mail:
        </p>

        <ul className={'mb-24'}>
          <li>
            <a
              className={'link_to_information'}
              href="mailto:bukreevaa@herzen.spb.ru"
            >
              bukreevaa@herzen.spb.ru
            </a>{' '}
            (для российских граждан)
          </li>
          <li>
            <a
              className={'link_to_information'}
              href="mailto:mss.allsunday97@yandex.ru"
            >
              mss.allsunday97@yandex.ru
            </a>{' '}
            (для иностранных граждан)
          </li>
        </ul>

        <p className={'mb-12'} style={{ fontWeight: 'bold' }}>
          Для дистанционного заключения договора Вам необходимо пройти следующие
          шаги:
        </p>

        <ol>
          <li className={'mb-12'}>
            Выбрать Плательщика (Заказчика) по договору, затем выбрать одно
            направление подготовки, на которое Вы планируете поступление и нажать на
            кнопку «НОВЫЙ ДОГОВОР».
          </li>
          <li className={'mb-12'}>
            Далее договор будет направлен на проверку сотруднику отдела аспирантуры.
          </li>
          <li className={'mb-12'}>
            После того, как договор будет проверен, у Вас появится возможность
            скачать договор, распечатать его, подписать и подгрузить скан-копию
            подписанного договора в личный кабинет.
          </li>
          <li className={'mb-12'}>
            Как только от Вас будет получен подписанный договор, в личном кабинете у
            Вас станет доступна квитанция для оплаты обучения.
          </li>
          <li className={'mb-24'}>
            <p className={'mb-12'}>
              После совершения оплаты необходимо направить подтверждающий документ
              (чек) на e-mail:
            </p>
            <ul>
              <li>
                <a
                  className={'link_to_information'}
                  href="mailto:bukreevaa@herzen.spb.ru"
                >
                  bukreevaa@herzen.spb.ru
                </a>{' '}
                (для российских граждан)
              </li>
              <li>
                <a
                  className={'link_to_information'}
                  href="mailto:mss.allsunday97@yandex.ru"
                >
                  mss.allsunday97@yandex.ru
                </a>{' '}
                (для иностранных граждан)
              </li>
            </ul>
          </li>
        </ol>

        <a
          className={'mb-12 link_to_information'}
          style={{ display: 'block' }}
          href="https://herzen.spb.ru/upload/medialibrary/912/2lhqip9ftosfj690n9e1riyi7309nie5/0110_40_01-O-stoimosti-platnykh-obrazovatelnykh-uslug-na-2023_24-uch.g.-Priem-2023-RF.pdf "
          target="_blank"
        >
          Стоимость платных образовательных услуг на 2023/2024 уч.год для граждан
          Российской Федерации, Республики Беларусь, Республики Казахстан, Республики
          Таджикистан, Кыргызской Республики
        </a>
        <a
          className={'mb-12 link_to_information'}
          style={{ display: 'block' }}
          href="https://herzen.spb.ru/upload/medialibrary/7cf/upxr0xjkq0h2q7bk15lw8donuehyp5wd/0110_41_01-priem-SNG-EAES-i-bez-gr.pdf"
          target="_blank"
        >
          Стоимость платных образовательных услуг на 2023/2024 уч.год для граждан
          стран СНГ и ЕАЭС (включая страны наблюдатели ЕАЭС и страны зоны свободной
          торговли ЕАЭС), лиц без гражданства
        </a>
        <a
          className={'link_to_information'}
          href="https://herzen.spb.ru/upload/medialibrary/804/6gtyq49bytrwjq1jr4gvbnvgh54gtpts/0110_42_01.pdf"
          target="_blank"
        >
          Стоимость платных образовательных услуг на 2023/2024 уч.год для граждан
          зарубежных стран, не входящих в СНГ и ЕАЭС (включая страны наблюдатели ЕАЭС
          и страны зоны свободной торговли ЕАЭС)
        </a>

        <div className="contract__section">
          <div className="contract__info">
            <p className="contract__info-first">
              Нажмите на кнопку "НОВЫЙ ДОГОВОР", выберите один из вариантов
              "Плательщик", заполните при необходимости поля и нажмите кнопку
              "Отправить договор на согласование".
            </p>
          </div>

          <header className="contract-list-page__header">
            {(contracts?.data.length === 0 || validStatuses) && (
              <RouteButtonLink to="create" type="button" theme="success">
                {terms.CREATE_CONTRACT}
              </RouteButtonLink>
            )}
          </header>
        </div>

        <ContractList />
      </div>
    )
  } else {
    return (
      <div>
        {isRF ? (
          <div className="contract__info">
            <p className="contract__info-first">
              <b>Договор об образовании и квитанция на оплату</b> будут сформированы
              и размещены в личном кабинете одним файлом. Подробная информация о
              сроках заключения договора и зачисления на платное обучение{' '}
              <a
                href="https://herzen.spb.ru/abiturients/informatsiya-o-prieme/postuplenie-dlya-grazhdan-rf/sroki-priyema/?clear_cache=Y"
                target="_blank"
                className="contract__info-link"
              >
                по следующей ссылке.
              </a>
            </p>
            <p className="contract__info-first">
              После получения договора, Вам необходимо проверить соответствие
              внесенных в договор сведений по выбранной Вами образовательной
              программе, а также - личные данные (точное написание ФИО, паспортные
              данные, телефон) Обучающегося и Заказчика (если это другое лицо).{' '}
              <b>
                Внимание! Если Вы обнаружили ошибку, то необходимо об этом сообщить
                на электронную почту
              </b>{' '}
              <a href="mailto:dg@herzen.spb.ru" className="contract__info-link">
                dg@herzen.spb.ru
              </a>
              .
            </p>
            <p className="contract__info-first">
              После совершения оплаты направьте подтверждающий документ на
              электронную почту{' '}
              <a href="mailto:dg@herzen.spb.ru" className="contract__info-link">
                dg@herzen.spb.ru
              </a>
            </p>

            <p className="contract__info-first">
              Если Вы отправили заявку, но не получили договор об образовании и
              квитанцию на оплату, напишите на электронную почту{' '}
              <a href="mailto:dg@herzen.spb.ru" className="contract__info-link">
                dg@herzen.spb.ru
              </a>
            </p>

            <p className="contract__info-first contract__info-first">
              Внимание! Сумма Вашей оплаты по договору должна полностью
              соответствовать сумме, указанной в счете (квитанции). Процент за свое
              обслуживание банк взимает сверх указанной в вашем счете (квитанции)
              суммы.
            </p>

            <p className="contract__info-first">
              <a
                href="https://herzen.spb.ru/abiturients/informatsiya-o-prieme/postuplenie-dlya-grazhdan-rf/magistratura/stoimost-obucheniya"
                target="_blank"
                className="contract__info-link"
              >
                Стоимость платных образовательных услуг на 2024/2025 уч.год для
                граждан РФ
              </a>
            </p>

            <p className="contract__info-bold">
              Подробная информация об{' '}
              <a
                href="https://www.herzen.spb.ru/about/struct-uni/otherdep/otdel-platnykh-obrazovatelnykh-uslug/obrazovatelnyy-kredit/"
                target="_blank"
                className="contract__info-link"
              >
                образовательном кредите
              </a>
            </p>

            <p className="contract__info-bold">
              Подробная информация об{' '}
              <a
                href="https://www.herzen.spb.ru/about/struct-uni/otherdep/otdel-platnykh-obrazovatelnykh-uslug/materinskiy-kapital/"
                target="_blank"
                className="contract__info-link"
              >
                использовании материнского капитала
              </a>
            </p>

            <p className="contract__info-bold">
              Информация о получении{' '}
              <a
                href="https://www.herzen.spb.ru/about/struct-uni/otherdep/otdel-platnykh-obrazovatelnykh-uslug/tax-vychet/"
                target="_blank"
                className="contract__info-link"
              >
                налогового вычета по расходам за обучение
              </a>
            </p>
          </div>
        ) : (
          <div className="contract__info">
            <p className="contract__info-first contract__info-center">
              <b>Уважаемый абитуриент!</b>
            </p>
            <p className="contract__info-first">
              У Вас есть возможность заключить договор об образовании.
            </p>
            <p className="contract__info-first">
              Выберите плательщика (заказчика) по договору, затем выберите одно
              направление подготовки, на которое планируете поступление, заполните
              (при необходимости) все поля и нажмите на кнопку «НОВЫЙ ДОГОВОР».
            </p>
            <p className="contract__info-first">
              Далее договор будет направлен на проверку сотруднику отдела по приему
              иностранных граждан на обучение.
            </p>
            <p className="contract__info-first">
              После проверки Вашего договора у Вас появится возможность скачать
              договор, распечатать его, подписать и подгрузить скан-копию
              подписанного договора в личный кабинет.
            </p>
            <p className="contract__info-first">
              После получения от Вас подписанного договора, Вам будет направлена
              квитанция для оплаты обучения на e-mail, указанный в личном кабинете.
            </p>
            <p className="contract__info-first">
              По вопросам, связанным с заключением договора, просим Вас обращаться по
              e-mail:
              <br />
              <a
                href="mailto:admission@herzen.spb.ru"
                className="contract__info-link contract__info-bold"
              >
                admission@herzen.spb.ru
              </a>
              .
            </p>
          </div>
        )}

        {/* Информация и кнопка добавления нового договора */}
        <div className="contract__section">
          <div className="contract__info">
            <p className="contract__info-first">
              Нажмите на кнопку "НОВЫЙ ДОГОВОР", выберите один из вариантов
              "Плательщик", заполните при необходимости поля и нажмите кнопку
              "Отправить договор на согласование".
            </p>
          </div>

          {/*
          Отображение только при выполнении условий:
          Выбран один из сценариев:
          - Бакалавр
          - Бакалавр иностранец
          - Магистр
          - Магистр иностранец
          */}
          {(scenarioId === scenarioKeys.level_training_bachelor.tdId ||
            scenarioId === scenarioKeys.level_training_bachelor_ino.tdId ||
            scenarioId === scenarioKeys.magistra_rf.tdId ||
            scenarioId === scenarioKeys.magistra_ino.tdId) && (
            <header className="contract-list-page__header">
              {(contracts?.data.length === 0 || validStatuses) && (
                <RouteButtonLink to="create" type="button" theme="success">
                  {terms.CREATE_CONTRACT}
                </RouteButtonLink>
              )}
            </header>
          )}
        </div>

        <ContractList />
      </div>
    )
  }
}

export default ContractListPage
