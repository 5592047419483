import React, { useMemo } from 'react'

import classNames from 'classnames'

import useAppSelector from '@hooks/useAppSelector'
import useBlockSendRequest from '@hooks/useBlockSendRequest'
import useCardForDownloadFile from '@hooks/useCardForDownloadFile'
import useDateBakIno from '@hooks/useDateBakIno'
import {
  selectStatementsElement,
  selectTrajectoryValue,
} from '@redux/actions/selectors'
import Button from '@ui/Button'
import Checkbox from '@ui/Checkbox'
import { formatDateTimeToRuFormat } from '@utils/date'

type CardForDownloadFileProps = {
  className?: string
  showCard: boolean
  title: string
  date: string
  direction?: string
  type?: string
  blankId: number
  acceptBlank: boolean
  scenarioKeys?: any
}

const CardForDownloadFile = ({
  className,
  showCard,
  title,
  date,
  direction,
  blankId,
  acceptBlank,
  scenarioKeys,
}: CardForDownloadFileProps) => {
  const { handleClickDownloadDocs, subscribeBlank } = useCardForDownloadFile(
    acceptBlank,
    blankId,
    title
  )

  const statement = useAppSelector(selectStatementsElement)
  const trajectory = useAppSelector(selectTrajectoryValue)

  const { isBlockSendRequest } = useDateBakIno({
    scenarioKeys,
    statementEl: statement,
    trajectory,
  })
  const { isBlockSendRequestBakRusPay, isBlockSendRequestMagRusPay } =
    useBlockSendRequest({
      scenarioKeys,
      statement,
    })

  const isDisabledForce = useMemo(() => {
    if (isBlockSendRequest) {
      return true
    }
    if (isBlockSendRequestBakRusPay) {
      return true
    }
    if (isBlockSendRequestMagRusPay) {
      return true
    }
    return false
  }, [isBlockSendRequest, isBlockSendRequestBakRusPay, isBlockSendRequestMagRusPay])

  return (
    <div className={classNames('card', className)}>
      <span className="card__header">{title}</span>
      <span className="card__content">
        Дата формирования:{' '}
        <strong>{date ? formatDateTimeToRuFormat(date) : '-'}</strong>
      </span>
      {direction && (
        <div className="card__content-direction">
          {' '}
          <span className="card__content">Приемная кампания / Направление: </span>
          <div>
            <strong>{direction}</strong>
          </div>
        </div>
      )}
      <div className="card__download">
        <Button
          type={'button'}
          disabled={isDisabledForce ? true : acceptBlank ? false : !showCard}
          onClick={handleClickDownloadDocs}
        >
          Скачать Документ
        </Button>
        <span className="card__download-text">
          {title === 'Заявление'
            ? 'Скачайте, проверьте данные и нажмите кнопку “Подписать”'
            : 'Скачайте и проверьте данные'}
        </span>
      </div>
      <div className="card__signature">
        <Checkbox
          className={
            acceptBlank ? 'active' : !showCard ? 'disabled' : date ? '' : 'disabled'
          }
          disabled={acceptBlank ? false : !showCard ? true : !date}
          theme="success-filled"
          checked={acceptBlank}
          onChange={subscribeBlank}
          text={'Подписать'}
        />
      </div>
    </div>
  )
}

export default CardForDownloadFile
